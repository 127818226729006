
.location-page {
    p {
        margin-bottom: 0px;
        line-height: 1.5;
        font-size: 16px;
    }

    .location-img {
        margin-top: 20px;
    }
}