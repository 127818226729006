@import './variables.scss';

#root .doctor-area {
    span {
        color: $color_primary !important;
    }

    .doctor-image {
        width: 200px;
        height: 200px;
        margin: auto;
        background: grey;

        &::before {
            background: $color_primary_light;
            color: $color_primary;
        }
    }

    li a {
        background: $color_primary_light;
        color: $color_primary;

        &:hover {
            background: $color_primary;
            color: white !important;
        }
    }
 }
