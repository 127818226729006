@import './variables.scss';

#root #banner-area {
  .fa-bell {
    background: $color_primary_light;
  }

  .main-banner {
    position: relative;

    &::after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      z-index: -1;
    }
  }

  .main-banner-content span,
  h1,
  p {
    color: white;
  }

  .circle-shape1,
  .circle-shape2,
  .shape1 {
    filter: grayscale(1);
  }

  .owl-dot {
    span {
      background: $color_primary_light !important;
    }

    button {
      background: none !important;
    }
  }

  .owl-dot.active > span {
    background: $color_primary !important;
  }
}
