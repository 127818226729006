@import './variables.scss';

#root #footer-area {
  p,
  a {
    color: #e7e7e7 !important;

    &:hover {
      color: $color_primary_light !important;
    }
  }

  .logo img {
    width: 150px;
  }

  .address p {
    color: #e7e7e7 !important;
  }

  li {
    color: #e7e7e7 !important;
  }

  a:hover {
    background: $color_primary_light;
    color: $color_primary !important;
  }
}
