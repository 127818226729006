@import './variables.scss';

#root .services-details-area {
  #side-navlink,
  #more-info {
    a.active {
      font-weight: bold;
      background: $color_primary_light;

      &::before {
        background: $color_primary;
      }
    }

    a:hover {
      background: $color-primary-light;

      &::before {
        background: $color_secondary;
      }
    }
  }

  .form-control:focus {
    border-color: $color_primary;
  }
}
