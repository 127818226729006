@import './variables.scss';

#root #about-area {

    padding-bottom: 20px;

    .about-content span {
        color: $color_primary;
    }

    i {
        color: $color_primary !important;
        background: $color_primary_light !important;
    }
}
