.dashboard-container {
  width: 90vw;
  min-height: 50vh;
  margin: auto;

  .input-container {
    width: 200px;
    height: 25px;
    border: 1px solid gray;

    input {
      width: inherit;
      border: none;
      height: inherit;
    }
  }

  .error {
    color: red;
  }

  .table-container {
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 100;
    text-align: center;

    .center-text {
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: white;
    }
  }
}
