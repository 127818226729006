@import './variables.scss';
@import './google_fonts.scss';

#root .App {
  button,
  .btn-primary {
    background: $color_primary !important;
  }

  a:not(.btn) {
    color: $color_primary !important;
  }

  .home-slides.owl-theme .owl-nav i {
    color: white !important;
  }

  .go-top {
    background: $color_primary_light !important;
    color: white !important;
  }

  i[class*='flaticon'] {
    color: $color_primary;

    &:hover {
      font-weight: bold;
    }
  }

  i[class='flaticon-check-mark'],
  i[class='flaticon-right-chevron'] {
    background: $color_primary_light;
    color: $color_primary;

    &:hover {
      background: $color_primary;
      color: white !important;
    }
  }

  .widget-area .widget .widget-title::before {
    background: $color_primary;
  }

  .toast {
    position: fixed;
    right: 16px;
    top: 120px;
    opacity: 1;
    z-index: 1000;
    overflow: visible;

    .toast-body {
      background-color: white !important;
    }

    &[data-status='true'] {
      background-color: forestgreen;
      opacity: 0.85;
    }

    &[data-status='false'] {
      background-color: red;
      opacity: 0.85;
    }
  }
}

.coming-soon {
  text-align: center;
  padding: 200px 16px;
  color: #127aff;
}

.disable {
  &::before {
    content: 'Please wait...';
    text-align: center;
    color: $color_primary;
    font-weight: bold;
  }

  form,
  input,
  button {
    opacity: 0.5;
    pointer-events: none;
  }
}
