@import './variables.scss';

#root .doctor-details-area {
  span {
    color: $color_primary !important;
  }

  .main-services-box {
    height: 280px;
    width: 280px;
    margin: 20px;

    div.icon {
      background-color: $color_primary;
    }

    &::before {
      background-color: $color_primary_light;
    }
  }

  .education-desc ul li:hover .icon {
    background-color: $color_primary_light;
    font-weight: bold;
  }

  a {
    word-break: break-all;
    font-size: 16px;
  }
}
