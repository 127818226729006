@import './variables.scss';

#main-services.main-services-area {
  .section-title {
    span {
      color: $color_primary !important;
    }
  }

  .main-services-box {
    height: 280px;
    width: 280px;
    margin: 20px;

    div.icon {
        background-color: $color_primary_light;
    }

    &::before {
        background-color: $color_primary_light;
    }
  }

  a {
    word-break: break-all;
    font-size: 16px;
  }
}
