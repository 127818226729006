@import './variables.scss';

#root .appointment-area {
  i,
  span {
    color: $color_primary;
  }

  .form-control:focus {
    border-color: $color_primary;
  }

  i.flaticon-right-chevron {
    background-color: $color_primary;
    color: white;
  }

  .form-group {
    select#insurance-select,
    select#age-select {
      display: block !important;
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
  }

  .appointment-content {
    margin: auto !important;
  }
}
