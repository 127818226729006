@import './variables.scss';

#root .header-area {
  .btn {
    padding: 15px 20px;
  }

  .logo {
    width: 150px;
  }

  .header-contact-info li i,
  .top-header-social li a {
    background: $color_primary_light;
    color: $color_primary;

    &:hover {
      background: $color_primary;
      color: white !important;
    }
  }

  .dropdown-menu {
    border-top-color: $color_primary_light !important;
  }

  .nav-link.active {
    color: $color_secondary;
  }

  .nav-link:hover {
    font-weight: bold;
  }

  @media screen and (min-width: 992px) {
    .nav-item {
      max-width: 200px;
    }
  }

  @media screen and (max-width: 1200px) {
    .header-contact-us-btn {
      display: none !important;
    }
  }
}
